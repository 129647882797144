import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GripError } from '@kpn-grip-fe/core';
import { filter } from 'rxjs/operators';
import { DigitalDataErrorModel, DigitalDataErrorSeverity, DigitalDataErrorType, DigitalDataModel } from '../models/digital-data.model';
import { StateService } from './state.service';

@Injectable()
export class DigitalDataService {
  private digitalData: DigitalDataModel = {
    page: {
      pageInfo: {
        pageName: null,
        destinationURL: null,
      },
      category: {
        primaryCategory: 'account',
      },
    },
    user: {
      profile: {
        profileInfo: {
          krnId: null,
          zmCustomerType: null,
          type: null,
        },
      },
    },
    error: [],
  };
  private intitialized: boolean = false;

  constructor(private readonly stateService: StateService, private readonly router: Router) {
    window.digitalData = this.digitalData;
  }

  public initialize(): void {
    if (this.intitialized) {
      throw new GripError('DigitalDataService has already been initialized.');
    }
    // this.loadAdobe();
    this.watchRouterEvents();
    this.digitalData.user.profile.profileInfo.zmCustomerType = this.stateService.tenant
      ? this.stateService.tenant.organizationTypeId
      : null;
    this.digitalData.user.profile.profileInfo.type = this.stateService.user ? 'signed-in' : 'guest';
    this.digitalData.user.profile.profileInfo.krnId = this.stateService.tenant?.krn;
    this.intitialized = true;
  }

  public trackError(severity: DigitalDataErrorSeverity, type: DigitalDataErrorType, message: string, code?: number, apiUrl?: string): void {
    const error: DigitalDataErrorModel = {
      errorInfo: {
        typeOfError: type,
      },
      attributes: {
        severity,
        message,
        code,
        apiUrl,
      },
    };
    this.digitalData.error.push(error);
  }

  public getDataTrackAttribute(eventName: string, position: string, label: string, element: string): string {
    return JSON.stringify({
      eventName,
      position,
      label,
      element,
    });
  }

  private watchRouterEvents(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.setPageName(event.urlAfterRedirects);
      this.setDestinationUrl();
    });
  }

  private setPageName(pathName: string): void {
    const pathParts = pathName.replace('/', '').split('/');
    pathParts.splice(0, 2);
    const name = pathParts.join(':');
    this.digitalData.page.pageInfo.pageName = `kpn:web:zm:mijnkpn:grip:${name}`;
  }

  private setDestinationUrl(): void {
    this.digitalData.page.pageInfo.destinationURL = window.location.href.replace(this.stateService.tenantShortName, 'deeplink');
  }

  // private loadAdobe(): void {
  //   let source: string;
  //   if (environment.production) {
  //     source = '//assets.adobedtm.com/launch-EN2558d2c15c4c46479e73a0166bca14da.min.js';
  //   } else if (environment.acceptance) {
  //     source = '//assets.adobedtm.com/launch-ENf8b8ab83aa7f4329966ee41df8eec374-staging.min.js';
  //   } else {
  //     source = '//assets.adobedtm.com/launch-EN4f9d071fe67440488fafaf4ac8c93abd-development.min.js';
  //   }

  //   const js = document.createElement('script');
  //   js.setAttribute('type', 'text/javascript');
  //   js.setAttribute('src', source);
  //   js.async = true;
  //   document.getElementsByTagName('head')[0].appendChild(js);
  // }
}
